import { mapGetters, mapActions } from 'vuex'
import { currencyFormat } from '@/utils'
import { isaFaq } from '@/constant'

export default {
  name: 'all-programs',
  components: {
    HorizontalProduct: () => import(/* webpackChunkName: "image" */ '@/components/card/HorizontalProductCard'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    TimeOutline2: () => import(/* webpackChunkName: "icon" */ '@/components/icons/TimeOutline2'),
    Accordion: () => import(/* webpackChunkName: "accordion" */ '@/components/accordion/Accordion')
  },
  data() {
    return {
      fullBootcampSlug: ['fullstack-web', 'fullstack-data'],
      fullBootcamp: [
        {
          url1: '',
          name: '',
          durationText: '',
          programName: '',
          price: ''
        },
        {
          url1: '',
          name: '',
          durationText: '',
          programName: '',
          price: ''
        }
      ],
      isBannerVisible: false,
      isaFaqs: isaFaq
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'adminB2C']),
    maxNameSize() {
      if (this.isMobile) return 50
      return 95
    },
    activeLanguange() {
      return this.$i18n.locale
    },
    stepBysteps() {
      return this.$i18n.messages['en'].isa.stepByStep.step
    }
  },
  filters: {
    currencyFormat
  },
  mounted() {
    this.getContent()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading', 'sendWhatsApp']),
    ...mapActions('program', ['getProgramByWebSlug']),
    ...mapActions('student', ['getStudentDetail']),
    toDetail(type, slug) {
      let program = ''
      switch (type) {
        case 'Bootcamp':
          program = 'bootcamp'
          break
        case 'Regular':
          program = 'regular'
          break
        case 'Short Course':
          program = 'regular'
          break
        default:
          program = 'prakerja'
          break
      }
      this.$router.push(`/class/${program}/${slug}`)
    },
    async getContent() {
      const resultBootcamp = new Promise((resolve) => {
        this.fullBootcampSlug.map(async (classes) => {
          const result = await this.getClassData(classes)
          if (result.slug === 'fullstack-web') this.fullBootcamp[0] = result
          if (result.slug === 'fullstack-data') this.fullBootcamp[1] = result
        })
        resolve(true)
      })
      if (resultBootcamp) {
        setTimeout(() => {
          this.isBannerVisible = true
        }, 800)
      }
    },
    toContact() {
      this.sendWhatsApp({ number: this.adminB2C, topic: 'Pembiayaan Bootcamp Secara ISA' })
    },
    async getClassData(slug) {
      const result = await this.getClassBySlug(slug)
      return result
    },
    async getClassBySlug(slug) {
      const result = await this.getProgramByWebSlug({
        slug
      })

      if (result.status === 200) {
        return result.data.data
      } else {
        return false
      }
    }
  }
}
