import { render, staticRenderFns } from "./ISA.vue?vue&type=template&id=ebd7ea8e&scoped=true"
import script from "./js/isa.js?vue&type=script&lang=js&external"
export * from "./js/isa.js?vue&type=script&lang=js&external"
import style0 from "./ISA.vue?vue&type=style&index=0&id=ebd7ea8e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebd7ea8e",
  null
  
)

export default component.exports